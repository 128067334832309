.kiteAnimation {
  position: absolute;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 150px;

  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#91379D;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#D255C3;}
}
