@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/responsive';

.textMediaBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;

  @include only-on(lgUp) {
    flex-direction: row;
  }
}

.textMediaBlock--Left {
  @include only-on(lgUp) {
    flex-direction: row-reverse;
  }
}

.textMediaBlock__text,
.textMediaBlock__media {
  max-width: 45%;
}

.textMediaBlock__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 20px;
  text-align: center;

  @include only-on(mdDown) {
    max-width: 250px;
  }

  @include only-on(lg) {
    margin: 0px;
    flex: 0 1 100%;
  }

  @include only-on(xl) {
    max-width: 400px;
    flex: 8 1 100%;
  }
}

.textMediaBlock__media {
  position: relative;
  width: 100%;
  flex: 0 1 100%;

  img {
    width: 100%;
    height: auto;
  }

  @include only-on(mdDown) {
    max-width: 250px;
  }

  @include only-on(lg) {
    flex: 0 1 100%;
  }

  @include only-on(xl) {
    max-width: 400px;
    flex: 10 1 100%;
  }
}

.plxScrollAnimation {
  max-width: 200px;
  max-height: 200px;
  position: absolute;
  top: 100px;
  left: 0;
}

.textMediaBlock__body {
  @extend .bodyText;
  width: 100%;

  ul {
    li {
      padding-left: 10px;
      text-align: left;
      margin: 20px 0px;
    }
  }
}

.textMediaBlock__header + .textMediaBlock__body {
  margin-top: 20px;
}

.textMediaBlock__header {
  @extend .titleText;
  width: 100%;
}

.textMediaBlock__number {
  @extend .numberText;
}

.textMediaBlock--LargeHeader {
  .textMediaBlock__text {
    @include only-on(lgUp) {
      align-items: flex-start;
    }
  }

  .textMediaBlock__header {
    @extend .headerTextSmall;
    @include only-on(lgUp) {
      text-align: left;
      margin-left: 20px;
    }
  }

  .textMediaBlock__body {
    @include only-on(lgUp) {
      text-align: left;
      margin-left: 20px;
    }
  }

  .textMediaBlock__media {
    padding: 20px;
  }
}

.textMediaBlock--ExtraSmallHeader {
  .textMediaBlock__header {
    font-size: 20px;
  }

  .textMediaBlock__body {
    font-size: 16px;
  }
}

@include only-on(lgUp) {
  .textMediaBlock--justify-left {
    .textMediaBlock__text {
      text-align: left;
      align-items: flex-start;
    }
  }
}

.textMediaBlock--ExtraSmallHeader.textMediaBlock--justify-left {
  text-align: left;
  .textMediaBlock__text {
    text-align: left;
    align-items: flex-start;

    @include only-on(mdDown) {
      max-width: 250px;
    }
  }
}

@include only-on(lgUp) {
  .textMediaBlock--noMedia {
    padding-top: 100px;
  }
}

.assetLink {
  @extend .linkText;
  text-align: center;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  max-width: 435px;
  .assetLink__icon {
    width: 21px;
    height: 21px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  @include only-on(lgUp) {
    .assetLink__icon {
      align-self: flex-start;
      text-align: left;
      margin: 20px 20px;
      flex-shrink: 0;
      margin-right: 20px;
      width: 45px;
      height: 45px;
    }
  }
}

.assetLink + .assetLink {
  margin-top: 10px;
}

.videoLinkModal {
  max-width: 80vw;
  max-height: 700px;
}

.videoLinkModal__content {
  display: inline-flex;
  background-color: black;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);

  iframe {
    width: 320px;
    height: 173px;
  }

  @include only-on(lgUp) {
    iframe {
      width: 960px;
      height: 520px;
    }
  }
}
