@import '../../../styles/responsive';

.seeSawAnimation {
  position: absolute;
  opacity: 0;
  top: 15px;
  width: 100%;
  height: 100%;

  .cls-1 {
    fill: #c0eff1;
  }

  .cls-2 {
    fill: #a8e6e9;
  }

  .cls-3 {
    fill: #feb902;
  }

  .cls-4 {
    fill: #b68c6b;
  }

  .cls-5 {
    fill: #b88e6e;
  }

  .cls-6 {
    fill: #493621;
  }

  .cls-7 {
    fill: #02888c;
  }

  .cls-8 {
    fill: #f89704;
  }
}
