@import '../../../styles/responsive';

.musicAnimation, .musicAnimation__part {
  position: absolute;
  top: 0px;
  .note {
    fill: #47341F;
  }
}

.musicAnimation__part {
  opacity: 0;
}

.musicAnimation__noteTop {
  width: 30px;
  top: -20px;
  left: 10px;

  @include only-on(mdDown){
    width: 15px;
    top: -10px;
    left: 5px;
  };
}

.musicAnimation__noteMiddle {
  width: 40px;
  top: 30px;
  left: 50px;

  @include only-on(mdDown){
    width: 20px;
    top: 15px;
    left: 25px;
  };
}

.musicAnimation__noteBottom {
  width: 30px;
  top: 80px;
  left: 100px;

  @include only-on(mdDown){
    width: 15px;
    top: 40px;
    left: 50px;
  };
}
