@import '../../../styles/responsive';

.puzzleAnimation,
.puzzleAnimation__part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.puzzleAnimation__part {
  opacity: 0;
}

.puzzleAnimationHand {
  top: -15px;
  left: -5px;
  @include only-on(mdDown) {
    top: 20px;
    left: 20px;
  }

  .st0 {
    fill: #b68d6c;
  }

  .st1 {
    fill: #feb801;
  }

  .st2 {
    fill: #feaa01;
  }

  .st3 {
    fill: #fda902;
  }
}

.puzzleAnimationSpark {
  @include only-on(mdDown) {
    top: -5px;
    left: -10px;
  }

  @include only-on(lgUp) {
    top: -20px;
    left: -30px;
  }

  .cls-1 {
    fill: #feba05;
  }

  .cls-2 {
    fill: #feba06;
  }

  .cls-3 {
    fill: #fcba06;
  }
}
