@import '../../../styles/responsive';

.rocketAnimation {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 250px;

  @include only-on(sm) {
    top: 20px;
    width: 100px;
  }

  .st0 {
    fill: #91379d;
  }
  .st1 {
    fill: #d255c3;
  }
  .st2 {
    fill: #ffb900;
  }
}

.rocketSingle {
  top: 200px;
  left: -75px;
  @include only-on(mdDown) {
    display: none;
  }
}
