@import '../../../styles/mixins';

.moonAnimation {
  position: absolute;
  top: -30px;
  left: 0;
  opacity: 0;
  width: 150px;

  @include only-on(sm) {
    width: 70px;
  }

  @include only-on(md) {
    width: 100px;
  }

  .note {
    fill: #47341F;
  }

  @include only-on(lgUp) {
    top: -60px;
  }

  .st0{fill:#FFB900;}
  .st1{fill:#FA9600;}
}
