.h5Animation, .h5Animation__part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.h5Animation__part {
  opacity: 0;
}

.h5YellowHand {
  left: 10px;
  .cls-1{fill:#feb701;}
}

.h5BlueHand {
  left: -10px;
  .cls-1{fill:#85aaf1;}
}

.h5LeftSpark {
  .cls-1{fill:#1fa6a0;}.cls-2{fill:#21a6a0;}
}

.h5MiddleSpark {
  .cls-1{fill:#068a8f;}.cls-2{fill:#078a8f;}.cls-3{fill:#078b90;}.cls-4{fill:#06898e;}
}

.h5RightSpark {
  .cls-1{fill:#05bab5;}.cls-2{fill:#06bab5;}.cls-3{fill:#07bab6;}
}
